/*eslint no-control-regex: "off"*/

import React from 'react';
import moment from 'moment';
import { Skeleton, Message } from './base';
import routingTable from '../routingTable';
import { getText } from '../i18n/i18next';
//import { isArray } from 'util';
import { property } from 'lodash';

const { isArray } = Array;

export const DateFormat = "YYYY-MM-DD";
export const DateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const DateTimeFormat2 = "YYYY-MM-DD HH:mm";
export const DateTimeFormat3 = "YYYY-MM-DD HH:mm:ss.SSS"; //block duplicate
export const TimeFormat = "HH:mm";
export const DateFormat_printing = "YYYY/MM/DD";

//export const OIAPIURL = 'https://capi2.ocean-insights.com/sailingschedules/v2/ports/?s=';

export const GUID = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export function checkTBCContainerNoFormatValid(container_no) {
    if (!CheckIsNullOrUndefined(container_no) && container_no.length >= 3 && container_no.length <= 6 && checkNormalCharactersOnly(container_no) && !checkWhiteSpace(container_no) && container_no.toLowerCase().includes('tbc')) {
        return true;
    }

    return false;
};

export const ArrToSelectBox = (ArrInput) => {
    let returnObj;

    if (Array.isArray(ArrInput)) {
        //console.log("HIHI Arr");
        if (ArrInput.length === 1) {
            if (ArrInput[0] !== "" && ArrInput[0] !== undefined) {
                returnObj = {
                    key: ArrInput[0],
                    label: ArrInput[0]
                };
            }
        } else {
            returnObj = [];
            ArrInput.map(item => {
                if (item !== "" && item !== undefined) {
                    returnObj.push({
                        key: item,
                        label: item
                    });
                }
                return true;
            });
        }
    } else if (ArrInput !== "") {
        returnObj = {
            key: ArrInput,
            label: ArrInput
        };
    }

    return returnObj;
};

export const ArrToFilterList = (ArrInput) => {
    let returnArr = [];
    try {
        ArrInput.map(record => {
            returnArr.push({
                text: record,
                value: record
            });
            return true;
        });
    } catch (error) {
        console.log("Common:ArrToFilterList:::", error);
    }
    return returnArr;
};

export const MomentDateToString = (dtMoment) => {
    if (moment.isMoment(dtMoment)) {
        let strDate = dtMoment.format(DateFormat);
        //console.log('MomentDateToString', strDate);
        return strDate;
    }
    return null;
};

export const MomentDateTimeToString = (dtMoment) => {
    if (moment.isMoment(dtMoment)) {
        let strDateTime = dtMoment.format('YYYY-MM-DD[T]HH:mm:ss');
        //console.log('MomentDateTimeToString', strDateTime);
        return strDateTime;
    }
    return null;
};

export const MoneyFieldFilter = (NumberInput, OriginalValue, DecimalPlaces = 2) => {
    let numberString = NumberInput.toString();
    let returnString = OriginalValue;

    if ((numberString.match(/\./g) || []).length <= 1 && (numberString.match(/[^0-9|.-]/g) || []).length === 0) {

        returnString = numberString.indexOf('.') > -1 ?
            numberString.toString().substring(0, numberString.indexOf('.') + (DecimalPlaces + 1))
            :
            numberString;
    }

    return returnString;
};

export const DefaultPageSize = () => {
    return parseInt(localStorage.getItem('default_page_size')) || 10;
}


export const FallbackElements = <>
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
</>;

export const SetTitle = (title) => {
    const baseTitle = 'M+R';
    document.title = (title || '') === '' ? baseTitle : `${baseTitle} - ${title}`;
};

export const GetSectionNameFromUrl = (match) => {
    const { path } = match;
    let r = routingTable.filter(r => r.path === path);
    if (r.length) {
        return r[0].name;
    }
    return '';
}

export const GetSectionDetailsFromUrl = (match) => {
    const { path } = match;
    let r = routingTable.filter(r => r.path === path);
    if (r.length) {
        return r[0];
    }
    return '';
}

export const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

export const DownloadAJAXBlob = (file, file_name) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Edge Fix
        window.navigator.msSaveOrOpenBlob(file, file_name);
    } else {
        console.log("Download Come here")
        var a = document.createElement('a');
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = file_name;
        document.body.appendChild(a); //Firefox fix
        a.click();
        setTimeout(function () { //Firefox Fix
            document.body.removeChild(a); //Firefox Fix
            window.URL.revokeObjectURL(fileURL)
        }, 100);
    }
}

export const RoundUp2Decimal = (inputNumber, DecimalPlaces = 2) => {
    //Before local Invoice //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(1))) / Math.pow(10, DecimalPlaces);
    //After local Invoice //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(0))) / Math.pow(10, DecimalPlaces);
    //20201118 11397 * 6.705(exchange rate) = ROUND(76416.885) should = 76416.89 instead of 76416.88 
    /*** 20201127 Test Case
    console.log("Test 1 Calculation(290 / 1.06) = 273.58::", Common.RoundUp2Decimal(290 / 1.06));
    console.log("Test 2 Calculation(11397 * 6.705) = 76416.89::", Common.RoundUp2Decimal(11397 * 6.705));
    console.log("Test 3 Calculation(1100 / 1.06) = 1037.74::", Common.RoundUp2Decimal(1100 / 1.06));
    console.log("Test 4 Calculation(487.14 * 6.4584) = 3146.14::", Common.RoundUp2Decimal(487.14 * 6.4584));
    console.log("Test 5 Calculation(245.53 * 6.4754) = 1589.90::", Common.RoundUp2Decimal(245.53 * 6.4754));

    console.log("Test 6 Calculation(undefined) = NaN::", Common.RoundUp2Decimal(undefined)); --Kin Request
    console.log("Test 7 Calculation(-849.630) = -850::", Common.RoundUp2Decimal(-849.630,0));
    ***/

    let inputNumberString = (inputNumber * 1).toFixed(DecimalPlaces + 3)
    let roundingDigit = parseInt(inputNumberString.substring(inputNumberString.length - 3, inputNumberString.length - 2))
    let returnNumber = parseFloat(inputNumberString.substring(0, inputNumberString.length - 3));

    if (roundingDigit >= 5) {
        if (returnNumber > 0) {
            returnNumber += 1 / Math.pow(10, DecimalPlaces)
        } else {
            returnNumber -= 1 / Math.pow(10, DecimalPlaces)
        }
    }

    return returnNumber;
    //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(DecimalPlaces))) / Math.pow(10, DecimalPlaces);
}

export const RoundDecimal = (inputNumber, DecimalPlaces = 2, Method = 'ROUND') => {
    //Before local Invoice //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(1))) / Math.pow(10, DecimalPlaces);
    //After local Invoice //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(0))) / Math.pow(10, DecimalPlaces);
    //20201118 11397 * 6.705(exchange rate) = ROUND(76416.885) should = 76416.89 instead of 76416.88 
    /*** 20201127 Test Case
    console.log("Test 1 Calculation(290 / 1.06) = 273.58::", Common.RoundUp2Decimal(290 / 1.06));
    console.log("Test 2 Calculation(11397 * 6.705) = 76416.89::", Common.RoundUp2Decimal(11397 * 6.705));
    console.log("Test 3 Calculation(1100 / 1.06) = 1037.74::", Common.RoundUp2Decimal(1100 / 1.06));
    console.log("Test 4 Calculation(487.14 * 6.4584) = 3146.14::", Common.RoundUp2Decimal(487.14 * 6.4584));
    console.log("Test 5 Calculation(245.53 * 6.4754) = 1589.90::", Common.RoundUp2Decimal(245.53 * 6.4754));

    console.log("Test 6 Calculation(undefined) = NaN::", Common.RoundUp2Decimal(undefined)); --Kin Request
    console.log("Test 7 Calculation(-849.630) = -850::", Common.RoundUp2Decimal(-849.630,0));
    ***/

    let inputNumberString = (inputNumber * 1).toFixed(DecimalPlaces + 3)
    let roundingDigit = parseInt(inputNumberString.substring(inputNumberString.length - 3, inputNumberString.length - 2))
    let returnNumber = parseFloat(inputNumberString.substring(0, inputNumberString.length - 3));

    if ((roundingDigit >= 5 && Method === 'ROUND')
        || Method === 'CEIL'
    ) {
        if (returnNumber > 0) {
            returnNumber += 1 / Math.pow(10, DecimalPlaces)
        } else {
            returnNumber -= 1 / Math.pow(10, DecimalPlaces)
        }
    }

    return returnNumber;
    //return Math.round(parseFloat((inputNumber * Math.pow(10, DecimalPlaces)).toFixed(DecimalPlaces))) / Math.pow(10, DecimalPlaces);
}

export const NumberFormatter = (inputNumber) => {
    let returnString;
    //20211007 #4805
    if (inputNumber === undefined) {
        returnString = ''
    } else {
        returnString = inputNumber.toString().replace('#', '');
    }
    //let negative = false;
    //if (returnString.charAt(0) === '-') {
    //    negative = true;
    //}
    if (returnString.indexOf('.') > -1) {
        let stringArr = returnString.split('.');
        returnString = stringArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + stringArr[1];
    } else {
        returnString = returnString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    //if (negative) {
    //    returnString = '-' + returnString
    //}

    return returnString;

}

export const NumberParser = (inputNumberString) => {
    return inputNumberString.toString().replace(/(,*)/g, '') + '#';
}
export const NumberOnChange = (inputNumberString) => {
    if (inputNumberString === '.') {
        inputNumberString = '0.'
    }
    return inputNumberString ? inputNumberString.toString().replace('#', '') : '0';
}

export const NumberParser_Antd4 = (inputNumberString) => {
    let returnString = inputNumberString.toString().replace(/(,*)/g, '');
    //returnString = returnString.replace(/\D/g, '');

    let negative = false;
    if (returnString.indexOf('-') > -1) {
        negative = true;
    }

    if (returnString.indexOf('.') > -1) {
        let stringArr = returnString.split('.');

        let frontNumber = stringArr[0].replace(/\D/g, '');
        if (frontNumber !== '0') {
            frontNumber = frontNumber.replace(/^0+/, '')
        }

        returnString = frontNumber + '.' + stringArr[1].replace(/\D/g, '');
    } else {
        returnString = returnString.replace(/^0+/, '').replace(/\D/g, '')
    }
    if (returnString === '.') {
        returnString = '0.'
    }

    if (negative) {
        returnString = '-' + returnString
    }

    return returnString ? returnString.toString().replace('#', '') : '0';
}

export const NumberOnBlur = (inputNumberString, DecimalPlaces = 2) => {
    let outputNumber = isNaN(parseFloat(NumberParser(inputNumberString))) ? 0 : parseFloat(NumberParser(inputNumberString));
    return outputNumber.toFixed(DecimalPlaces)
}

export const NumberOnBlur_Antd4 = (inputNumberString, min, max, DecimalPlaces = 2) => {
    let outputNumber = isNaN(parseFloat(NumberParser(inputNumberString))) ? 0 : parseFloat(NumberParser(inputNumberString));
    if (outputNumber < min) {
        outputNumber = min
    }
    if (outputNumber > max) {
        outputNumber = max
    }
    //return DecimalPlaces < 0 ? outputNumber.toString() : outputNumber.toFixed(DecimalPlaces);
    return outputNumber.toString();
}

export const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const dynamicSort2Column = (propertyA, propertyB) => {
    return function (a, b) {
        if (a[propertyA] === b[propertyA]) {
            return a[propertyB] < b[propertyB] ? -1 : 1
        } else {
            return a[propertyA] < b[propertyA] ? -1 : 1
        }
    }
}

export const CheckIsNullOrUndefined = (input) => (input === null || input === undefined);

export const CheckIsEmptyString = (input) => (input === null || input === undefined || input === '');

export const CheckIsEmptyArray = (input) => (input === null || input === undefined || !isArray(input) || input.length === 0);

export const CheckIsEmptyObject = (input) => (input === null || input === undefined || Object.keys(input).length === 0);

export const validateEmail = (email) => {
    var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    if (email) email = email.toLowerCase();

    return re.test(email);
}

export const showAjaxErrorMsg = (error) => {
    if (error.response && error.response.status === 404) {
        Message.warn(getText('msgNoRecordFound'), 10);
    }
    else if (error.response && error.response.data) {
        Message.error(`${error.response.data.Message}`, 10);
    }
    else {
        Message.error(`${error}`, 10);
    }
}

export const checkStrongPassword = (pw) => {
    let patten = /^(?=(.*\d))(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;

    return patten.test(pw);
}

export function checkNormalCharactersOnly(text) {
    let englishReg = /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9- ]+$|^$/;

    return englishReg.test(text);
}

export function replaceToUpperCase(text) {
    let englishReg = /[^~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9- \r\n]/gi;

    if (text) {
        return text.replace(englishReg, '').toUpperCase();
    }

    return '';
}

export function trimWhiteSpace(text) {
    let englishReg = /[\r\n\t\f\v]/gi;

    if (text) {
        return text.replace(englishReg, '').trim();
    }

    return '';
}

export function checkWhiteSpace(text) {
    let noWhiteSpaceReg = /^[^\r\n\t\f\v\s]*$/;

    return !noWhiteSpaceReg.test(text);
}

export function isfCnsNumReg(isf_cns_num_type) {
    if (isf_cns_num_type === 'EIN') {
        return /^(\d{2})-(\d{7})([A-Z0-9]{2})$/;
    }
    else if (isf_cns_num_type === 'CBP') {
        return /^(\d{6})-(\d{5})$/;
    }
    else if (isf_cns_num_type === 'SSN') {
        return /^(\d{3})-(\d{2})-(\d{4})$/;
    }
    else if (isf_cns_num_type === 'Passport') {
        return /^[A-z0-9]{1,12}$/;
    }
    else {
        return null;
    }
}

export function validateUSAssignedID(text, isf_cns_num_type) {
    const pattern = isfCnsNumReg(isf_cns_num_type);
    if (pattern) {
        return pattern.test(text);
    } else {
        return true;
    }
};

export function formatCnsIorValue(value, type) {
    let formattedValue = '';

    // Remove existing hyphens from the input value
    value = value.replace(/-/g, '');

    // Add hyphens based on the ID type and expected format
    if (type === 'EIN') {
        if (value.length > 2) {
            formattedValue += value.substr(0, 2) + '-';
            value = value.substr(2);
        }
    }
    else if (type === 'CBP') {
        if (value.length > 6) {
            formattedValue += value.substr(0, 6) + '-';
            value = value.substr(6);
        }
    }
    else if (type === 'SSN') {
        if (value.length > 3) {
            formattedValue += value.substr(0, 3) + '-';
            value = value.substr(3);
        }
        if (value.length > 2 && formattedValue.includes('-')) {
            formattedValue += value.substr(0, 2) + '-';
            value = value.substr(2);
        }
    }

    // Combine the formatted value with the remaining input value
    formattedValue += value;

    return formattedValue || value;
}


export function getOMSETDRange(po) {
    let etdRange = { omsETDRangeFrom: null, omsETDRangeTo: null };
    if (isArray(po)) {
        po.forEach(po => {
            if (CheckIsNullOrUndefined(etdRange.omsETDRangeFrom)) {
                etdRange.omsETDRangeFrom = po.earliest_ship_date;
            }
            else {
                if (!CheckIsEmptyString(po.earliest_ship_date) && moment(po.earliest_ship_date).isValid() && moment(po.earliest_ship_date).isAfter(etdRange.omsETDRangeFrom)) {
                    etdRange.omsETDRangeFrom = po.earliest_ship_date;
                }
            }

            if (CheckIsNullOrUndefined(etdRange.omsETDRangeTo)) {
                etdRange.omsETDRangeTo = po.est_ship_date;
            }
            else {
                if (!CheckIsEmptyString(po.est_ship_date) && moment(po.est_ship_date).isValid() && moment(po.est_ship_date).isBefore(etdRange.omsETDRangeTo)) {
                    etdRange.omsETDRangeTo = po.est_ship_date;
                }
            }
        });

        if (CheckIsEmptyString(etdRange.omsETDRangeFrom) || CheckIsEmptyString(etdRange.omsETDRangeTo) || (moment(etdRange.omsETDRangeFrom).isValid() && moment(etdRange.omsETDRangeTo).isValid() && moment(etdRange.omsETDRangeFrom).isAfter(etdRange.omsETDRangeTo))) {
            etdRange = { omsETDRangeFrom: null, omsETDRangeTo: null };
        }
    }

    return etdRange;
}


export const ExcelDateToJSDate = (serial) => {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

export const getBrowser = () => {
    let returnBrowser = '';

    if (window.chrome !== undefined) {
        returnBrowser = 'chrome';
    } else if (window.navigator.userAgent.indexOf("Edg") > -1) {
        returnBrowser = 'edge';
    } else if (window.navigator.userAgent.indexOf("Mozilla") > -1) {
        returnBrowser = 'firefox';
    }
    //console.log(window.chrome, window.navigator, returnBrowser)
    
    return returnBrowser
}

export const countHTMLtextSizeInMB = text => {
    let count = 0;
    if (navigator.platform.indexOf('Win') === 0)
    {
        count = calculateHTMLTextSize(text, { lineBreaks: 2 });
    }
    else 
    {
        count = calculateHTMLTextSize(text);
    }
    return count > 0 ? (Math.round((count/1024/1024)*100)/100) : 0;
}

const calculateHTMLTextSize = (text, options) => {
    let crlf = /(\r?\n|\r)/g
    let whitespace = /(\r?\n|\r|\s+)/g;

    // Set option defaults
    options = options || {};
    options.lineBreaks = options.lineBreaks || 1;
    options.ignoreWhitespace = options.ignoreWhitespace || false;
    
    let length = text.length;
    let nonAscii = length - text.replace(/[\u0100-\uFFFF]/g, '').length;
    let lineBreaks = length - text.replace(crlf, '').length; 
    
    if (options.ignoreWhitespace) {
        // Strip whitespace
        text = text.replace(whitespace, '');
        
        return text.length + nonAscii;
    }
    else {
        return length + nonAscii + Math.max(0, options.lineBreaks * (lineBreaks - 1));
    }
}

export const formResetFields = (form, fields) => {
    form && form.resetFields && form.resetFields(fields);
}

export const formSetFieldsValue = (form, values) => {
    form && form.setFieldsValue && form.setFieldsValue(values);
}

export const formGetFieldValue = (form, field) => {
    return form && form.getFieldValue && form.getFieldValue(field);
}

export const formSetFields = (form, fields) => {
    form && form.setFields && form.setFields(fields);
}

export const formValidateFields = (form, fields) => {
    form && form.validateFields && form.validateFields(fields);
}

export const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const isENSParticipant = (countryCode, cityCode) => {
    return ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI",
        "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU",
        "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE",
        "CH", "NO"
        ].includes((countryCode || '').toUpperCase()) ||
        ["GBBEL", "GBCLR", "GBLDY", "GBWPT", "GBLAR", "GBPTR"].includes((cityCode || '').toUpperCase());
}

export const getEORIRegExp = (countryCode, cityCode, nullable) => {
    if (isENSParticipant(countryCode, cityCode) &&
            !["CH", "NO"].includes((countryCode || '').toUpperCase()) &&
            !["GBBEL", "GBCLR", "GBLDY", "GBWPT", "GBLAR", "GBPTR"].includes((cityCode || '').toUpperCase()))
        return nullable ? new RegExp(/^[A-Z]{2}[a-zA-Z0-9]{0,15}$|^$/) : new RegExp(/^[A-Z]{2}[a-zA-Z0-9]{0,15}$/);
    else
        return new RegExp(/^.{0,}$/);
}

export const jobTypeMapping = {
    c: 'Cancelled',
    i: 'Inbound',
    o: 'Outbound'
};

export const jobStatusMapping = {
    opn: 'OPEN',
    del: 'DELETED'
};